/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "primeflex/primeflex.css";

@import "primeicons/primeicons.css";

*{
  font-family: "Roboto", sans-serif;
}

:root{
  --primary: #263A7D;
  --secondary: #43C0C2;
}

body, p{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
